import React from "react";
import { navigate } from "gatsby";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import Layout from "../../components/Layout";
import PropTypes from "prop-types";
import { CoursePageContainer } from "../../components/CoursePageContainer";
import Showdown from "showdown";
import { courseSeoDetails } from "../../constants/courseSeoDetails";
import {
  INITIAL_COURSE_RATINGS,
  INITIAL_COURSE_REVIEWERS,
  SENIOR_COACH,
} from "../../utils/localization";
import { useSelector } from "react-redux";

const CoursePage = (props) => {
  const { pageContext } = props;
  const { course, courses } = pageContext;
  const userCourses = useSelector((state) => state.userCourses);
  const userPlans = useSelector((state) => state.userPlans);

  let pageTitle, pageDescription, pageSchema;
  pageTitle = course?.title ?? courseSeoDetails.default.pageTitle;
  pageDescription =
    course?.description ?? courseSeoDetails.default.pageDescription;
  pageSchema = course?.pageSchema;

  const converter = new Showdown.Converter();
  let _long_description = converter.makeHtml(course?.description);

  const userSubscribedCourse = userCourses?.courses.filter(
    (subscribedCourse) => subscribedCourse?.id === course?.id
  );

  useEffect(() => {
    if (isEmpty(pageContext)) {
      navigate("/404");
    }
  });

  return (
    <Layout
      title={pageTitle}
      description={pageDescription}
      schemaMarkup={pageSchema}
    >
      <CoursePageContainer
        courseId={course?.id}
        title={course?.title}
        description={course?.shortDescription}
        instructor={course?.coaches?.find(
          (coach) => coach?.userType === SENIOR_COACH
        )}
        course_length={course?.duration}
        long_description={_long_description}
        price={course?.price}
        ratings={{
          stars:
            Math.round(
              ((INITIAL_COURSE_RATINGS + course?.averageRating) / 2) * 10
            ) / 10,
          reviewers: INITIAL_COURSE_REVIEWERS + course?.reviews?.length,
        }}
        learners={course?.totalLearnersCount}
        course_level={course?.level}
        course_enable={course?.overviews}
        chapters={
          course?.chapters?.map((chapter) => ({
            ...chapter,
            description: converter.makeHtml(chapter.description),
          })) ?? []
        }
        reviews={course?.reviews ?? []}
        isReviewShown={course?.isReviewShown}
        project_files={2}
        job_titles={course?.careerAhead}
        job_companies={course?.jobOpportunities ?? []}
        job_companies_count={course?.jobOpportunityCount}
        // backend_slug={backend_slug}
        category={course?.category}
        allCourses={courses || []}
        Faqs={course?.faqs}
        continuation_courses={course?.relatedCourses ?? []}
        chaptersCount={course?.chaptersCount}
        hasSubscribed={userSubscribedCourse[0]?.hasSubscribed}
        productLine={course?.productLine}
        userPlans={userPlans}
      />
    </Layout>
  );
};

CoursePage.propTypes = {
  pageContext: PropTypes.object,
};

export default CoursePage;
